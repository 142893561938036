import { FC } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import Section from 'components/Section'
import { useLeaseContract, useLeaseContractsList } from 'services/contracts/hooks/useLeaseContracts'

const WidgetConsultant: FC = () => {
  const { formatMessage } = useIntl()
  const { leaseContractsList } = useLeaseContractsList()

  const activeLeases = leaseContractsList.filter(({ leaseStatus }) => leaseStatus === 0)
  const itemWithHighestId = activeLeases.reduce((max, item) => {
    return item?.id > max?.id ? item : max
  }, activeLeases[0])

  const { leaseContract, isLoading } = useLeaseContract(itemWithHighestId?.id)

  if (!activeLeases) {
    return null
  }

  if (isLoading) {
    return (
      <Section
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '15px'
        }}>
        <Typography variant='h5' fontWeight={500}>
          {formatMessage({
            id: 'consultantWidget.yourConsultant',
            defaultMessage: 'Twój konsultant:'
          })}
        </Typography>
        <CircularProgress />
      </Section>
    )
  }

  return (
    <Section
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '5px'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          gap: { xs: '5px', lg: '20px' }
        }}>
        <Typography variant='h5' fontWeight={500}>
          {formatMessage({
            id: 'consultantWidget.yourConsultant',
            defaultMessage: 'Twój konsultant:'
          })}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5px'
          }}>
          <Typography variant='h6' fontWeight={400}>
            {leaseContract?.owner?.firstName ? leaseContract?.owner.firstName : null}
          </Typography>
          <Typography variant='h6' fontWeight={400}>
            {leaseContract?.owner?.lastName ? leaseContract?.owner.lastName : null}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          gap: { xs: '5px', lg: '15px' },
          alignItems: 'start'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '5px',
            paddingRight: '15px',
            borderRight: { xs: '0px', lg: '1px solid #181818' }
          }}>
          <Typography variant='h6' fontWeight={500}>
            {formatMessage({
              id: 'consultantWidget.tel',
              defaultMessage: 'tel:'
            })}
          </Typography>
          <Typography variant='h6' fontWeight={400}>
            {leaseContract?.owner?.mobile ? leaseContract?.owner.mobile : '-'}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '5px'
          }}>
          <Typography variant='h6' fontWeight={500}>
            {formatMessage({
              id: 'consultantWidget.email',
              defaultMessage: 'email:'
            })}
          </Typography>
          <Typography variant='h6' fontWeight={400}>
            {leaseContract?.owner?.email ? leaseContract?.owner.email : '-'}
          </Typography>
        </Box>
      </Box>
    </Section>
  )
}

export default WidgetConsultant
